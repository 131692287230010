<template>
  <div class="container-fluid">
      <div id="packServicios" class="row pt-5">
          <div class="col-12 pb-3">
            <h2 class="title-h1 text-center">Nuestro Pack de Servicios</h2>
          </div>
      </div>
  </div>
  <div class="d-flex flex-row justify-content-center flex-wrap">
    <div class="card m-4">
      <div class="card-body text-center">
        <img width="94" height="95" class="img-fluid pb-3" src="@/assets/img/salvavidas.png" alt="Ibancar">
        <!-- <h5 class="card-title">Card title</h5> -->
        <h3 class="card-text">
          <strong>Garantía de amortización del
          préstamo por siniestro total</strong></h3>
        <p class="card-text">
          O posibilidad de entrega de su vehículo por siniestro parcial
        </p>
        <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
      </div>
    </div>
    <div class="card m-4">
      <div class="card-body text-center">
        <img width="83" height="80" class="img-fluid pb-3" src="@/assets/img/armtool.svg" alt="Ibancar">
        <!-- <h5 class="card-title">Card title</h5> -->
        <h3 class="card-text">
          <strong>Revisión técnica anual de mantenimiento incluída de su vehículo</strong></h3>
        <p class="card-text">
           Revisión técnica anual de mantenimiento incluída de su vehículo
        </p>
        <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
      </div>
    </div>
    <div class="card m-4">
      <div class="card-body text-center">
        <img width="84" height="87" class="img-fluid pb-3" src="@/assets/img/vectorIcon.png" alt="Ibancar">
        <!-- <h5 class="card-title">Card title</h5> -->
        <h3 class="card-text">
          <strong>Estudio incluído para solicitar aumento del préstamo</strong></h3>
        <p class="card-text">
           Siempre que no se haya solicitado con anterioridad el máximo a prestar
        </p>
        <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
      </div>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
    <div class="card m-4">
      <div class="card-body text-center">
        <img width="76" height="73" class="img-fluid pb-3" src="@/assets/img/calendario.svg" alt="Ibancar">
        <!-- <h5 class="card-title">Card title</h5> -->
        <h3 class="card-text">
          <strong>Servicio personalizado de obtención de citas ITV</strong></h3>
        <p class="card-text">
           Servicio de avisos automático para caducidad de citas ITV
        </p>
        <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
      </div>
    </div>
    <div class="card m-4">
      <div class="card-body text-center">
        <img width="116" height="59" class="img-fluid pb-3" src="@/assets/img/alfombra.svg" alt="Ibancar">
        <!-- <h5 class="card-title">Card title</h5> -->
        <h3 class="card-text">
          <strong>Área de clientes personalizada en nuestra Web</strong></h3>
        <p class="card-text">
           Con acceso privado, donde se incluye toda la información acerca de su préstamo, citas ITV, amortización, informes...
        </p>
        <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
      </div>
    </div>
    <div class="card m-4">
      <div class="card-body text-center">
        <img width="89" height="90" class="img-fluid pb-3" src="@/assets/img/document.svg" alt="Ibancar">
        <!-- <h5 class="card-title">Card title</h5> -->
        <h3 class="card-text">
          <strong>Informe Ganvam del estado de su vehículo</strong></h3>
          <p class="card-text">
             A petición del cliente, con un máximo de 2 al año
          </p>
        <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
      </div>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
    <div class="card m-4">
      <div class="card-body text-center">
        <img width="75" height="81" class="img-fluid pb-3" src="@/assets/img/present.svg" alt="Ibancar">
        <!-- <h5 class="card-title">Card title</h5> -->
        <h3 class="card-text">
          <strong>Flexibilidad en los pagos</strong></h3>
          <p class="card-text">
            5 días de cortesía para abonar la cuota
          </p>
        <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
      </div>
    </div>
    <div class="card m-4">
      <div class="card-body text-center">
        <img width="67" height="102" class="img-fluid pb-3" src="@/assets/img/creditcard.svg" alt="Ibancar">
        <!-- <h5 class="card-title">Card title</h5> -->
        <h3 class="card-text">
          <strong>Diferentes formas de pago</strong></h3>
          <p class="card-text">
            Pago con tarjeta o ingresos
          </p>
        <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
      </div>
    </div>
    <div class="card m-4">
      <div class="card-body text-center">
        <img width="79" height="78" class="img-fluid pb-3" src="@/assets/img/operadora.svg" alt="Ibancar">
        <!-- <h5 class="card-title">Card title</h5> -->
        <h3 class="card-text">
          <strong>Gestor asignado</strong></h3>
          <p class="card-text">
            Atención al cliente de lunes a viernes de 9:00 a 20:00 y sábados de 10:00 a 14:00
          </p>
        <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
      </div>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
  </div>
  <div class="d-flex flex-row justify-content-center flex-wrap pt-3 pb-5">
    <div class="card m-4">
      <div class="card-body text-center">
        <img width="81" height="81" class="img-fluid pb-3" src="@/assets/img/fechapago.svg" alt="Ibancar">
        <!-- <h5 class="card-title">Card title</h5> -->
        <h3 class="card-text">
          <strong>Tu eliges la fecha de pago</strong></h3>
          <p class="card-text">
            Pagas en la fecha que mejor te venga
          </p>
        <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
      </div>
    </div>

    <div class="card m-4">
      <div class="card-body text-center">
        <img width="70" height="82" class="img-fluid pb-3" src="@/assets/img/car_money.svg" alt="Ibancar">
        <!-- <h5 class="card-title">Card title</h5> -->
        <h3 class="card-text">
          <strong>Servicio de compra de coche</strong></h3>
          <p class="card-text">
            Si no puedes hacer frente al préstamo, te compramos el coche, solventamos la deuda y te devolvemos la diferencia.
          </p>
        <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
      </div>
    </div>

  </div>

  <div class="d-flex flex-column justify-content-center align-items-center bg text-white">
          <h2 class="title-h1 text-white align-center">¿Necesitas más razones para elegirnos?</h2>
          <p id="parrafo_razones" class="card-text">Descubre por qué es tu mejor opción frente a tu banco u otras empresas del sector.</p>
          <a id="btn-comparativa" href="/#tabla" class="btn btn-orange">Ver comparativa Ibancar</a>
  </div>
</template>

<script>
export default {
    name: 'CreditoCocheS3',
    methods: {
      async goTo(route,hash) {
          await this.$router.push({path: route, hash: hash});
      }
    }
}
</script>

<style lang="scss" scoped>
.bg {
    background-color: #004680;
}
.card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.card-text {
  text-align: center;
}

  // Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs, sm) {
    .title-h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 4.5vh;
    }
    .btn-orange {
      margin-bottom: 5px;
    }
    .card {
      width: 500px;
    }
    .card-text {
      font-size: 12px;
    }
    #parrafo_razones {
      font-size: 16px;
    }

    .card-body {
      padding: 0.75rem;
    }
    .img-fluid {
      width: 30%;
    }
    .bg {
      margin-top: 15px;
      height: 34vh;
    }
    .align-center {
      text-align: center;
    }
    #btn-comparativa {
      width: 95%;
    }
}

@include media-breakpoint-between(md, lg) {
    .title-h1 {
      font-size: 24px;
      font-weight: 600;
    }
    .card {
      width: 35vh;
      height: auto;
    }
    .card-text {
      font-size: 16px;
    }
    .card-body {
      padding: 0.75rem;
    }
    .img-fluid {
      width: 35%;
    }
    .bg {
      margin-top: 15px;
      height: 34vh;
    }
    .btn-orange {
      margin-bottom: 5px;
    }
}

@include media-breakpoint-between(xl, xxl) {
    .bg {
      height: 34vh;
    }
    .title-h1 {
      font-size: 32px;
      font-weight: 600;
    }
    .card {
      width: 37vh;
      height: auto;
    }
    .card-text {
      font-size: 18px;
    }


}
</style>