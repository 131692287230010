<template>
  <div class="bg-img">
    <div class="row justify-content-center pt-5">
      <div id="desktop" class="col-12 col-xl-6">
        <div class="row justify-content-center">
          <div class="col-xl-11 col-lg-11 col-md-11 offset-md-1 offset-lg-0 offset-xl-3 p-3">
              <h1 class="title title-1 pb-3 pt-5 text-left"><strong>La mejor solución para un crédito con tu coche</strong></h1>
              <p class="title title-2 text-left"><span class="green-text pr-2">✓</span> Préstamo con tu coche como aval</p>
              <p class="title title-2 text-left"><span class="green-text pr-2">✓</span> Sin cambio de titularidad</p>
              <p class="title title-2 text-left"><span class="green-text pr-2">✓</span> Consigue hasta <strong>6.000€</strong> en <strong>24 horas</strong></p>
              <p class="title title-2 text-left"><span class="green-text pr-2">✓</span> Proceso <strong>100% Online</strong></p>
              <p class="title title-2 text-left"><span class="green-text pr-2">✓</span> Aceptamos personas en <strong>Asnef</strong></p>
              <p class="title title-2 text-left"><span class="green-text pr-2">✓</span> Sin renting</p>
          </div>
        </div>
      </div>
      <div  id="mobile" class="col-12 col-xl-6 pt-3">
        <div class="d-flex row align-items-end">
          <img width="411" height="253" class="img-fluid" src="@/assets/img/creditocochebg.webp" alt="ibancar">
          <div class="text-over-image">
            <p class="title">El único préstamo con el aval de tu coche donde no cambiamos la titularidad.<br />Consigue hasta 6.000€ en 24 horas.</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="row justify-content-center">
          <div id="zona_formulario" class="col-12">
            <NuevoFormulario/>
          </div>
        </div>
      </div>
      <div class="col-12 p-2 section-footer"> 
        <div>
          <p class="pt-3"></p>
        </div>
      </div>
    </div>
  </div>
    <div class="bg-footer row justify-content-center align-items-center">
      <div class="">
          <p id="desktop" class="title">Tu coche te avala sin retirada de vehículo, sin cambio de <br> titularidad y sin alquiler por conducir.</p>
          <p id="mobile" class="title">Tu coche te avala sin retirada de vehículo, sin cambio de titularidad y sin alquiler por conducir.</p>
      </div>
    </div>
</template>

<script>
import NuevoFormulario from "../NuevoFormulario/NuevoFormulario.vue";

export default {
  name: "CreditoCocheS1",
  components:{
    NuevoFormulario,
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';

.img-fluid {
  background-color: $blue;
}
.bg-footer {
    background-size: cover;
    background-color: $blue;
    height: 145px;
}
.title {
  width: 100%;
  font-family: 'Montserrat';
  font-display: swap;
  font-style: normal;
  font-size: 1.5rem;
  line-height: auto;
  text-align: center;
  color: $white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.title-1{
  font-size: 44px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.title-2{
  font-family: 'Montserrat';
  font-display: swap;
  font-size: 26px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.green-text {
  color: $green-2;
  font-weight: bold;
}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {

    h4 {
      display: none;
    }
    .title-1{
      font-size: 24px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    #desktop {
      display: none;
    }
    .bg-footer {
      .title {
        padding: 0.20rem;
        font-size: 1.4rem !important;
        line-height: 35px;
      }
    }
    .img-fluid {
      width: 100%;
    }
    .text-muted {
      color: $black;
    }
    .section-footer{
      text-align: center;
      background-color: $blue-2;
      padding: 0;
    }
    .text-over-image {
      position: inherit;
      bottom: 0px;
      right: 0px;
      width: 100%;
      text-align: center;
      background: rgba(0, 70, 128, 0.75) ;
      color: white;
      padding-top: 15px;
      font-weight: bold;
    }

}

@include media-breakpoint-between(md,lg) {
  #mobile {
    display: none;
  }
  .bg-footer {
    h2 {
      font-size: 1.5rem;
      line-height: 45px;
    }
  }
  .bg-img {
    margin-top: 90px;
    background-color: $blue;
    background-image: url("../../assets/img/fondocreditococheWEBP.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .text-muted {
    color: $white !important;
  }
  .section-footer{
    text-align: center;
    padding: 0
  }

}

@include media-breakpoint-between(xl,xxl) {
  #mobile {
    display: none;
  }
  #zona_formulario {
    padding-left: 2.5rem;
    margin-top: 35vh;
  }
  .bg-footer {
    h2 {
      font-size: 1.9rem !important;
      line-height: 50px;
    }
  }
  .bg-img {
    margin-top: 90px;
    background-color: $blue;
    background-image: url("../../assets/img/fondocreditococheWEBP.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }
.text-muted {
  color: $white !important;
}
.section-footer{
  text-align: center;
  padding: 0
}


}



</style>
