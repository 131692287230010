<template>
  <div>
    <CreditoCocheS1/>
    <CreditoCocheS2/>
    <CreditoCocheS3/>
    <CreditoCocheS4/>
  </div>
</template>
<script>
// @ is an alias to /src
import CreditoCocheS1 from '@/components/CreditoCoche/CreditoCocheS1.vue'
import CreditoCocheS2 from '@/components/CreditoCoche/CreditoCocheS2.vue'
import CreditoCocheS3 from '@/components/CreditoCoche/CreditoCocheS3.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";

export default {
  name: 'CreditoCoche',
  components: {
    CreditoCocheS1,
    CreditoCocheS2,
    CreditoCocheS3,
    CreditoCocheS4
  },
  setup() {
    useMeta({
      title:'Crédito con tu coche ▷ 100% online y seguro, sin cambio de titular y sin entregar el vehículo',
      meta: [
        {name:'robots', content: store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Credito con aval de coche sin cambio de titularidad. Consigue crédito hasta 6.000€ en 24h. Evita alquilar su coche y amortiza desde el primer día. Consigue dinero rápido.'},
        {name:'keywords', content:'Credito con aval, Credito aval coche, Dinero urgente online, Crédito con coche, Préstamos rápidos y créditos rápidos de dinero. Empeña tu coche. Refinanciación de coches.'}
      ]
    })
  }
}
</script>