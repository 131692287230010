<template>
<div>
 <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Crédito por tu coche</strong></h2>
     <p>¿Estás buscando un préstamo rápido, pero no quieres renunciar a la propiedad de tus bienes materiales? ¡No te preocupes! Ibancar es una empresa que ofrece el crédito coche, donde se utiliza tu vehículo como garantía.</p>
    <p>Con Ibancar, puedes conseguir el dinero que necesitas en 24 horas y sin tener que sacrificar la propiedad del vehículo que amas. A continuación, veamos cómo funciona el crédito de Ibancar y por qué es una opción tan atractiva. </p>

      <h3 class="naranja"><strong>¿Cómo funciona el crédito coche en Ibancar?</strong></h3>
   <p>Al solicitar el crédito coche en Ibancar, tu vehículo se utilizará como aval. Esto significa que no tienes que implicar a otras personas, ni esperar la aprobación de tu banco. Y, lo mejor de todo es que Ibancar no te retira el vehículo, no cambia su titularidad y tampoco te impone condiciones sobre cómo utilizarlo mientras pagas el crédito. </p>
<p>En esencia, es como un préstamo personal donde amortizas capital e intereses desde el primer día.</p>


      <h3 class="naranja"><strong>¿Cuáles son las ventajas del crédito coche en Ibancar?</strong></h3>
        <p>Una de las principales ventajas del crédito coche en Ibancar es que te permite seguir conduciendo tu propio vehículo sin ningún tipo de condiciones mientras pagas el crédito. Es decir, no se te retira el coche, ni tienes que cambiar su titularidad. </p>
        <p>Esto hace que el crédito en Ibancar sea muy atractivo para las personas que dependen de sus vehículos con regularidad y no tienen acceso al transporte público u otras formas de transporte. </p>
        <p>Otra ventaja es que con el crédito en Ibancar puedes disponer del dinero en 24h. Solo debes completar un formulario en línea y recibirás una propuesta sin ningún compromiso. Según los datos que facilites puedes estar pre-aprobado de forma inmediata.</p>
        <p>Por último, el proceso de solicitud del crédito es 100% digital. Puedes conseguir el dinero que necesitas desde la comodidad de tu hogar sin tener que desplazarte a ningún lugar. No necesitas enviar documentación original ni copia de llaves del coche, porque el coche es tuyo sin ningún renting. </p>
        <p>El envío de la documentación y la firma de contrato es online para ahorrarte esfuerzo y tiempo, y así conseguir que tengas el dinero en 24 horas.</p>
        <p>En el caso de tener dudas acerca de la propuesta o de las condiciones del crédito de Ibancar, podrás contactar con nosotros, y uno de nuestros gestores estará encantado de explicarte todos los detalles. En todo momento tendrás a un gestor financiero asignado que te ayudará paso a paso a conseguir el crédito.</p>

      <h3 class="naranja"><strong>¿Cuáles son los requisitos que se necesitan para poder solicitar el crédito coche en Ibancar?</strong></h3>
<p>     Para poder solicitar el crédito coche en Ibancar, deberás enviar:</p>

      <ul>
   <li>Escáner de tu DNI, ambas caras en fondo blanco.</li>
<li>Última nómina, pensión o autónomo.</li>
<li>Foto Selfie con DNI.</li>
<li>Permiso de circulación y ficha técnica.</li>
<li>1 Foto del cuentakilómetros con tu DNI.</li>
<li>4 fotos del coche </li>

      </ul>

      <p>Podrás obtener más información acerca de la documentación que necesitas enviar en el apartado de abajo de preguntas comunes. También podrás encontrar información acerca de las condiciones en las que debe estar el vehículo para poder solicitar el crédito con Ibancar.
</p>

      <h3 class="naranja"><strong>¿Desde que parte de España se puede solicitar el crédito coche en Ibancar?</strong></h3>
<p>El crédito coche en Ibancar está disponible en toda España. Puedes comenzar con tu solicitud desde cualquier lugar de la península o las Islas Baleares de manera online y recibir el dinero en tu cuenta en menos de 24 horas.</p>
<p>Así que si estás buscando una forma rápida de conseguir el dinero que necesitas sin tener que renunciar a tu vehículo, ¡considera la posibilidad de solicitar el crédito en Ibancar hoy mismo!</p>
    
 </div>
  <div class="container-fluid pt-5">  
      <div class="row justify-content-center pb-3">
          <div class="col-xl-8 col-lg-8 col-md-10 col-sm-12 col-xs-12">
                <div class="card">
                    <h2 class="card-header text-center p-3">Tu crédito con coche como aval en 24 horas</h2>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <!-- div se activa con pantallas vertical -móviles, tablets- -->
                            <div id="vertical" class="col-xs-12 col-sm-12 col-md-10 col-lg-12 col-xl-3 col-xxl-3 text-center">
                                <img width="336" height="224" class="img-fluid" src="@/assets/img/disenosintitulo2.webp" alt="ibancar">
                            </div>
                            <!--  -->
                            <div class="col-xs-12 col-sm-12 col-md-10 col-lg-12 col-xl-9 col-xxl-9 padding">
                                <p class="card-text">El crédito que te ayuda en el momento oportuno.</p>
                                <p class="card-text">
                                    Completa el formulario y te enviaremos una propuesta sin ningún compromiso. Según los datos que nos facilites puedes estar pre-aprobado de forma inmediata. 
                                </p>
                                <p class="card-text">
                                    En caso de tener dudas acerca de la propuesta o de las condiciones de nuestro préstamo con aval de coche, podrás contactar con nosotros por los mismos canales y estaremos encantados de explicarte todos los detalles. 
                                </p>
                                <p class="card-text">
                                    En todo momento <strong>tendrás a un gestor financiero asignado</strong> que te ayudará paso a paso a conseguir el préstamo.
                                </p>
                            </div>
                            <!-- Este div se activa cuando la pantlla es horizontal -desktop- -->
                            <div id="horizontal" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 col-xxl-3 text-center">
                                <img width="336" height="224" class="img-fluid" src="@/assets/img/disenosintitulo2.webp" alt="ibancar">
                            </div>
                            <!--  -->
                        </div>
                        <div class="row">
                            <div class="col-12 text-center p-2 pt-4">
                                <button type="button" class="btn-orange text-center p-2" @click="goTo('/como-funciona/','#documentacion')">Documentación necesaria</button>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
      </div>
  </div>
  <div class="container-fluid pt-5">
      <div class="row justify-content-center pb-3">
          <div class="col-xl-8 col-lg-8 col-md-10 col-sm-12 col-xs-12">
                <div class="card">
                    <h2 class="card-header text-center p-3">Gestión personalizada e inmediata</h2>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-xs-12 col-sm-12 col-md-10 col-lg-12 col-xl-3 col-xxl-3 text-center">
                                <img width="333" height="222" class="img-fluid" src="@/assets/img/disenosintitulo1.webp" alt="ibancar">
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-10 col-lg-12 col-xl-9 col-xxl-9 padding">
                                <p class="card-text">A la hora de realizar un <strong>crédito con aval de coche</strong> es normal tener dudas, por ese motivo en Ibancar tenemos disponibles todos los canales y estamos a tu disposión para aclarte cualquier duda.</p>
                                <p class="card-text">
                                    Nuestro comprimiso es que entiendas a la perfección las condiciones del préstamo y así puedas escoger la opción que mejor te convenga.
                                </p>
                                <p class="card-text">
                                    Recuerda que durante el préstamo siempre tendrás a un gestor para realizar cualquier gestión con tu préstamo. 
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center p-4">
                                <button type="button" @click="goTo('/contacto')"  class="btn-orange text-center p-2 btn-contacta">Contacta con nosotros</button>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
      </div>
  </div>
  <div class="container-fluid pt-5">
      <div class="row justify-content-center pb-3">
          <div class="col-xl-8 col-lg-8 col-md-10 col-sm-12 col-xs-12">
                <div class="card">
                    <h2 class="card-header text-center p-3">Crédito coche 100% digital</h2>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <!-- div se activa con pantallas vertical -móviles, tablets- -->
                            <div id="vertical" class="col-xs-12 col-sm-12 col-md-10 col-lg-12 col-xl-3 col-xxl-3 text-center">
                                <img width="336" height="222" class="img-fluid" src="@/assets/img/image_22.webp" alt="ibancar">
                            </div>
                            <!--  -->
                            <div class="col-xs-12 col-sm-12 col-md-10 col-lg-12 col-xl-9 col-xxl-9 padding">
                                <p class="card-text">Nuestros créditos tienen un proceso totalmente digital, puedes conseguir el dinero sin moverte de casa.</p>
                                <p class="card-text">
                                    No necesitas enviar documentación original ni copia de llaves del coche, porque el coche es tuyo sin ningún renting.
                                </p>
                                <p class="card-text">
                                    El envío de la documentación y la firma de contrato es online para ahorrarte esfuerzo y tiempos, y así conseguir que tengas el dinero en 24 horas.
                                </p>
                            </div>
                            <!-- Este div se activa cuando la pantlla es horizontal -desktop- -->
                            <div id="horizontal" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 col-xxl-3 text-center">
                                <img width="336" height="222" class="img-fluid" src="@/assets/img/image_22.webp" alt="ibancar">
                            </div>
                            <!--  -->
                        </div>
                        <div class="row">
                            <div class="col-12 text-center p-4">
                                <button type="button" class="btn-orange text-center p-2" @click="goTo('/','')">Solicitar préstamo</button>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
      </div>
  </div>  </div>

</template>

<script>
export default {
    name: 'CreditoCocheS2',
    methods: {
        goTo(route, hash) {
        this.$router.push({path: route, hash: hash});
        }
    }
}
</script>

<style scoped lang="scss">


.card-header {
    background-color: #fbfbfb;
    border-bottom: none;
    font-weight: 600;
}
.card-body {
    background-color: #fbfbfb;
}


  // Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs, md) {
    .padding {
        padding-left: 0 !important;
    }
    .card-header {
        font-size: 1.5rem;
    }
    .card-text {
        font-size: 14px;
    }
    .img-fluid {
        padding-bottom: 2rem;
    }
    #horizontal {
        display: none;
    }

    .card-header {
        font-size: 24px;
    }
    .btn-contacta {
        font-size: 20px !important;
    }
}

@include media-breakpoint-between(md, lg) {
    #horizontal {
        display: none;
    }

    .card-text {
        font-size: 18px;
    }
    .padding {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .img-fluid {
        width: 55vh;
        height: auto;
        padding-bottom: 5%;
    }
    .card-header {
        font-size: 32px;
    }
}


@include media-breakpoint-between(xl, xxl) {
    #vertical {
        display: none;
    }
    .card-text {
    font-size: 18px;
    }
    .padding {
        padding-left: 2rem;
        padding-right: 2.5rem;
    }

    .card-header {
        font-size: 36px;
    }

}

@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}

.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}


@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }

  a.no:hover {
    height: 100%;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

</style>